import { prompt } from './prompts';
import Swal from 'sweetalert2';
import { getCookie } from './utils';

document.addEventListener('bundleLoaded', async function () {
    const scannerButton = document.getElementById('scannerBtn');
    if (!scannerButton) return;

    scannerButton.addEventListener('click', async function () {
        let intervalId;

        const updateHtml = (message, icon = 'loader') => {
            return `
                <div class="title">${message}</div>
                <i class="icon ${icon}"></i>
                ${gettext('możesz też<br>wpisać ręcznie')}
                <input type="text" id="manualCodeInput" class="swal2-input code-input" />
            `;
        };

        const handleResult = (resultData) => {
            clearInterval(intervalId);
            Swal.close();
            window.location.href = resultData.url;
        };

        const checkCollectorData = async () => {
            try {
                const url = '/api/collectors/check/';
                const response = await fetch(url);
                const data = await response.json();

                if (response.status === 200) {
                    if (data.status === 'loading') {
                        Swal.update({
                            html: updateHtml(
                                gettext('Ładowanie danych... Proszę czekać.'),
                                data.enova_id,
                                data.is_device,
                            ),
                        });
                    } else {
                        handleResult({ source: 'api', ...data });
                    }
                } else if (response.status === 400) {
                    clearInterval(intervalId);
                    Swal.update({
                        html: updateHtml(data.message, 'warning'),
                    });
                }
            } catch (error) {
                clearInterval(intervalId);
                console.error('Error checking collector data:', error);
                Swal.update({
                    html: updateHtml(
                        gettext(
                            'Błąd podczas sprawdzania danych kolektora.',
                            'warning',
                        ),
                    ),
                });
            }
        };

        prompt({
            title: '',
            html: updateHtml(
                gettext(
                    'Oczekiwanie na dane z kolektora.<br> Zeskanuj kod kreskowy.',
                ),
            ),
            preConfirm: async () => {
                const input = document.getElementById('manualCodeInput');
                const value = input.value;
                if (!value) {
                    input.classList.add('error');
                    return false;
                }

                clearInterval(intervalId);
                try {
                    const manualInputUrl = '/api/collectors/collect/manual/';
                    const manualInputResponse = await fetch(manualInputUrl, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'X-CSRFTOKEN': getCookie('csrftoken'),
                        },
                        body: JSON.stringify({ product: value }),
                    });

                    intervalId = setInterval(checkCollectorData, 2000);

                    if (manualInputResponse.status === 200) {
                        Swal.update({
                            html: updateHtml(
                                gettext(
                                    'Dane zostały wysłane, oczekiwanie na przetworzenie...',
                                ),
                            ),
                        });
                    } else if (manualInputResponse.status === 400) {
                        const errorData = await manualInputResponse.json();
                        clearInterval(intervalId);
                        Swal.update({
                            html: updateHtml(errorData.message, 'warning'),
                        });
                    } else {
                        clearInterval(intervalId);
                        Swal.update({
                            html: updateHtml(
                                gettext(
                                    'Błąd podczas wysyłania ręcznego kodu.',
                                ),
                                'warning',
                            ),
                        });
                    }
                } catch (error) {
                    console.error('Error sending manual input:', error);
                    clearInterval(intervalId);
                    Swal.update({
                        html: updateHtml(
                            gettext('Błąd podczas wysyłania ręcznego kodu.'),
                            'warning',
                        ),
                    });
                }

                return false;
            },
            showCancelButton: false,
            showConfirmButton: true,
            allowOutsideClick: false,
            customClass: {
                container: 'scanner-modal',
                htmlContainer: 'scanner-container',
                confirmButton: 'btn-submit',
            },
            width: '75vw',
        }).then(async (result) => {
            clearInterval(intervalId);
        });

        intervalId = setInterval(checkCollectorData, 2000);
    });
});
