import interact from 'interactjs';

// --- Helper Function for Draggable Initialization ---
function makeDraggable(windowElement, draggingContainer) {
    interact(windowElement)
        .draggable({
            allowFrom: '.window-header',
            ignoreFrom: 'button',
            inertia: true,
            modifiers: [
                interact.modifiers.restrictRect({
                    restriction: draggingContainer,
                    endOnly: true,
                }),
            ],
            autoScroll: false,
            listeners: {
                start: (event) => {
                    window.getSelection().removeAllRanges();
                    document.body.classList.add('no-select');
                },
                move: dragMoveListener,
                end: (event) => {
                    document.body.classList.remove('no-select');
                    saveWindowState(event.target);
                },
            },
        })
        .resizable({
            edges: {
                left: true,
                right: true,
                bottom: true,
                top: false,
            },
            listeners: {
                start: (event) => {
                    window.getSelection().removeAllRanges();
                    document.body.classList.add('no-select');
                },
                move(event) {
                    const target = event.target;
                    let x = parseFloat(target.getAttribute('data-x')) || 0;
                    let y = parseFloat(target.getAttribute('data-y')) || 0;

                    target.style.width = event.rect.width + 'px';
                    target.style.height = event.rect.height + 'px';

                    x += event.deltaRect.left;
                    y += event.deltaRect.top;

                    target.style.transform =
                        'translate(' + x + 'px,' + y + 'px)';

                    target.setAttribute('data-x', x);
                    target.setAttribute('data-y', y);
                },
                end: (event) => {
                    document.body.classList.remove('no-select');
                    saveWindowState(event.target);
                },
            },
            modifiers: [
                interact.modifiers.restrictSize({
                    min: { width: 330, height: 330 },
                }),
            ],
            inertia: true,
        });
}

// --- Helper Function for Removing Draggable ---
function removeDraggable(windowElement) {
    interact(windowElement).unset();
    windowElement.style.transform = '';
    windowElement.style.width = '';
    windowElement.style.height = '';
    windowElement.removeAttribute('data-x');
    windowElement.removeAttribute('data-y');
    document.body.classList.remove('no-select');
}

// --- Drag Move Listener ---
function dragMoveListener(event) {
    const target = event.target;
    const x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx;
    const y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy;

    target.style.transform = 'translate(' + x + 'px, ' + y + 'px)';

    target.setAttribute('data-x', x);
    target.setAttribute('data-y', y);
}

// --- Save Window State ---
export function saveWindowState(windowElement) {
    if (!windowElement) return;

    const windowId = windowElement.id;
    if (!windowId) return;

    const state = {
        open: windowElement.classList.contains('window-open'),
        draggable: windowElement.classList.contains('window-draggable'),
        x: windowElement.getAttribute('data-x') || '0',
        y: windowElement.getAttribute('data-y') || '0',
        width: windowElement.style.width,
        height: windowElement.style.height,
        left: windowElement.style.left,
        top: windowElement.style.top,
    };

    localStorage.setItem(`windowState-${windowId}`, JSON.stringify(state));
}

// --- Load Window State ---
function loadWindowState(windowElement) {
    if (!windowElement) return;

    const windowId = windowElement.id;
    if (!windowId) return;

    const savedState = localStorage.getItem(`windowState-${windowId}`);
    if (savedState) {
        const state = JSON.parse(savedState);

        if (state.open) {
            windowElement.classList.add('window-open');
        } else {
            windowElement.classList.remove('window-open');
        }
        if (state.draggable) {
            windowElement.classList.add('window-draggable');
        }

        windowElement.style.width = state.width;
        windowElement.style.height = state.height;
        windowElement.style.left = state.left;
        windowElement.style.top = state.top;

        if (state.x !== '0' || state.y !== '0') {
            windowElement.style.transform = `translate(${state.x}px, ${state.y}px)`;
            windowElement.setAttribute('data-x', state.x);
            windowElement.setAttribute('data-y', state.y);
        }

        if (state.draggable) {
            const draggingContainer =
                document.querySelector('.disassembly-content-wrapper') ||
                document.body;
            makeDraggable(windowElement, draggingContainer);
        }
    }
}

export function initializeWindows(container) {
    container.querySelectorAll('.window-toggle').forEach((btn) => {
        btn.addEventListener('click', function () {
            const window = document.getElementById(btn.dataset.window);
            if (!window) return;
            if (window.dataset.left) window.style.left = window.dataset.left;
            window.classList.toggle('window-open');
            saveWindowState(window);
        });
    });

    container.querySelectorAll('.window .window-close').forEach((closeBtn) => {
        closeBtn.addEventListener('click', function () {
            const window = closeBtn.closest('.window');
            if (!window) return;
            window.classList.remove('window-open');
            saveWindowState(window);
        });
    });

    container.querySelectorAll('.window .window-popup').forEach((popupBtn) => {
        popupBtn.addEventListener('click', function () {
            const window = this.closest('.window');
            if (!window) return;
            window.classList.toggle('window-draggable');
            const draggingContainer =
                container.querySelector('.disassembly-content-wrapper') ||
                document.body;

            if (window.classList.contains('window-draggable')) {
                makeDraggable(window, draggingContainer);
                window.style.left = '25%';
                window.style.top = window.dataset.top || '25%';
            } else {
                removeDraggable(window);
            }
            saveWindowState(window);
        });
    });

    container.querySelectorAll('.window').forEach(loadWindowState);
}
