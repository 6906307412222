import { initializeTable } from '../tables';
import RowContextMenuButtonColumn from '../formatters/RowContextMenuButtonColumn';
import { TabulatorFull as Tabulator } from 'tabulator-tables';
import toggleNestedColumn from '../formatters/ToggleNestedColumn';
import { getCookie, sendDeleteRequest, sendPatchRequest } from '../../utils';
import { addAlert } from '../../alerts';
import { inputPrompt } from '../../prompts';

export default function initializeManualsTable({ ...options }) {
    const rootElement = document.querySelector('#manualsTable');
    initializeTable({
        tableElement: rootElement,
        filtersElement: document.querySelector('#manualsFilters'),
        tabsElement: document.querySelector('.table-tabs'),
        ajaxURL: rootElement.dataset.ajaxUrl,
        rowContextMenu: function (e, row) {
            const rowData = row.getData();
            const menu = [
                {
                    label: gettext('Edytuj'),
                    disabled: rowData.is_archived,
                    action: function (e, row) {
                        window.location.href = rowData.update_url;
                    },
                },
                {
                    label: gettext('Nowa rozbiórka'),
                    disabled:
                        !rowData.versions.some((obj) => obj.status === 1) ||
                        rowData.is_archived,
                    action: function (e, row) {
                        inputPrompt({
                            options: {
                                title: gettext('Wprowadź ID urządzenia'),
                                confirmText: gettext('Rozpocznij rozbiórkę'),
                                confirmAction: ({ value }) => {
                                    window.location.href = `/disassembly/create/${rowData.id}/${value}/`;
                                },
                            },
                        });
                    },
                },
                {
                    label: rowData.is_archived
                        ? gettext('Przywróć')
                        : gettext('Archiwizuj'),
                    action: async function (e, row) {
                        const response = await sendPatchRequest(
                            `/api/manuals/${rowData.id}`,
                            {
                                is_archived: !rowData.is_archived,
                            },
                        );
                        const data = await response.json();
                        if (response.ok) {
                            rowData.is_archived = !rowData.is_archived;
                            row.delete();
                            if (rowData.is_archived) {
                                addAlert(
                                    'success',
                                    gettext(
                                        'Pomyślnie zarchiwizowano instrukcje.',
                                    ),
                                );
                            } else {
                                addAlert(
                                    'success',
                                    gettext(
                                        'Pomyślnie przywrócono instrukcje.',
                                    ),
                                );
                            }
                        } else {
                            addAlert('error', data.message);
                        }
                    },
                },
                {
                    label: gettext('Usuń'),
                    disabled: !rowData.versions.every(
                        (obj) => obj.status === 0,
                    ),
                    action: async function (e, row) {
                        const response = await sendDeleteRequest(
                            `/api/manuals/${rowData.id}`,
                        );
                        const data = await response.json();
                        if (response.ok) {
                            addAlert('success', data.message);
                            row.delete();
                        } else {
                            addAlert('error', data.message);
                        }
                    },
                },
            ];
            return menu;
        },
        columns: [
            {
                resizable: false,
                width: 60,
                headerSort: false,
                formatter: toggleNestedColumn,
            },
            { title: '#', formatter: 'rownum', width: 60, headerSort: false },
            {
                title: gettext('Typ'),
                field: 'device_type',
                resizable: false,
            },
            {
                title: gettext('Grupa'),
                field: 'device_group',
                resizable: false,
            },
            {
                title: gettext('Producent'),
                field: 'device_manufacturer',
                resizable: false,
            },
            {
                title: gettext('Model'),
                field: 'device_model',
                resizable: false,
            },
            {
                title: gettext('Akcja'),
                resizable: false,
                headerSort: false,
                width: 100,
                formatter: RowContextMenuButtonColumn,
            },
        ],
        rowFormatter: (row) => {
            const containerElement = document.createElement('div');
            const nestedTableElement = document.createElement('div');
            containerElement.classList.add('nested-table-container');
            containerElement.classList.add('hidden');
            nestedTableElement.classList.add('nested-table');

            containerElement.appendChild(nestedTableElement);
            row.getElement().appendChild(containerElement);

            const nestedTable = new Tabulator(nestedTableElement, {
                layout: 'fitColumns',
                data: row.getData().versions,
                selectableRows: false,
                rowHeight: 32,
                rowContextMenu: function (e, row) {
                    const rowData = row.getData();
                    return [
                        {
                            label: gettext('Duplikuj'),
                            action: async function (e, row) {
                                const response = await fetch(
                                    `/api/manuals/versions/${rowData.id}/duplicate`,
                                    {
                                        method: 'POST',
                                        headers: {
                                            'Content-Type': 'application/json',
                                            'X-CSRFToken':
                                                getCookie('csrftoken'),
                                        },
                                    },
                                );
                                const data = await response.json();
                                if (response.ok) {
                                    window.location.href = data.update_url;
                                } else {
                                    addAlert('error', data.message);
                                }
                            },
                        },
                    ];
                },
                columns: [
                    {
                        title: '#',
                        formatter: 'rownum',
                        width: 60,
                        resizable: false,
                        headerSort: false,
                    },
                    {
                        title: gettext('Numer wersji'),
                        field: 'version_number',
                        resizable: false,
                        headerSort: false,
                    },
                    {
                        title: gettext('Data'),
                        field: 'publication_time',
                        formatter: 'datetime',
                        formatterParams: {
                            inputFormat: 'iso',
                            outputFormat: 'dd-MM-yy',
                        },
                        resizable: false,
                        headerSort: false,
                        sorter: 'datetime',
                        sorterParams: {
                            format: 'iso',
                            alignEmptyValues: 'top',
                        },
                    },
                    {
                        title: gettext('Autor instrukcji'),
                        field: 'author_name',
                        resizable: false,
                        headerSort: false,
                    },
                    {
                        title: gettext('Status'),
                        field: 'status_name',
                        resizable: false,
                        headerSort: false,
                    },
                    {
                        title: gettext('Akcja'),
                        resizable: false,
                        headerSort: false,
                        width: 100,
                        formatter: RowContextMenuButtonColumn,
                    },
                ],
            });
        },
        ...options,
    });
}
