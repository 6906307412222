import { initializeTable } from '../tables';
import RowContextMenuButtonColumn from '../formatters/RowContextMenuButtonColumn';
import { TabulatorFull as Tabulator } from 'tabulator-tables';
import toggleNestedColumn from '../formatters/ToggleNestedColumn';
import { getCookie } from '../../utils';
import { addAlert } from '../../alerts';

export default function initializeDisassembliesTable({ ...options }) {
    const rootElement = document.querySelector('#disassembliesTable');
    initializeTable({
        tableElement: rootElement,
        filtersElement: document.querySelector('#disassembliesFilters'),
        tabsElement: document.querySelector('.table-tabs'),
        ajaxURL: rootElement.dataset.ajaxUrl,
        rowContextMenu: function (e, row) {
            const rowData = row.getData();
            const menu = [
                {
                    label: gettext('Kontynuuj rozbiórkę'),
                    disabled: rowData.is_finished,
                    action: function (e, row) {
                        window.location.href = rowData.update_url;
                    },
                },
                {
                    label: gettext('Usuń'),
                    disabled: rowData.is_finished,
                    action: async function (e, row) {
                        const response = await fetch(
                            `/api/disassembly/${rowData.id}`,
                            {
                                method: 'DELETE',
                                headers: {
                                    'Content-Type': 'application/json',
                                    'X-CSRFToken': getCookie('csrftoken'),
                                },
                            },
                        );
                        const data = await response.json();
                        if (response.ok) {
                            addAlert('success', data.message);
                            row.delete();
                        } else {
                            addAlert('error', data.message);
                        }
                    },
                },
            ];
            return menu;
        },
        columns: [
            { title: '#', formatter: 'rownum', width: 60, headerSort: false },
            {
                title: gettext('Urządzenie'),
                field: 'device.enova_id',
                resizable: false,
            },
            {
                title: gettext('Użytkownik'),
                field: 'user_name',
                resizable: false,
            },
            {
                title: gettext('Początek rozbiórki'),
                field: 'start_time',
                formatter: 'datetime',
                formatterParams: {
                    inputFormat: 'iso',
                    outputFormat: 'dd-MM-yy',
                },
                resizable: false,
                sorter: 'datetime',
                sorterParams: {
                    format: 'iso',
                    alignEmptyValues: 'top',
                },
            },
            {
                title: gettext('Koniec rozbiórki'),
                field: 'end_time',
                formatter: 'datetime',
                formatterParams: {
                    inputFormat: 'iso',
                    outputFormat: 'dd-MM-yy',
                },
                resizable: false,
                sorter: 'datetime',
                sorterParams: {
                    format: 'iso',
                    alignEmptyValues: 'top',
                },
            },
            {
                title: gettext('Akcja'),
                resizable: false,
                headerSort: false,
                width: 100,
                formatter: RowContextMenuButtonColumn,
            },
        ],
        ...options,
    });
}
