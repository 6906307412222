import { sendPatchRequest, sendPostRequest } from '../utils';
import { addAlert, genericErrorAlert } from '../alerts';
import { saveWindowState } from '../windows';

export function initializeDisassemblyActions() {
    function checkAllConfirmed() {
        const allConfirmed = Array.from(
            document.querySelectorAll('.disassembly-action'),
        ).every((action) => action.dataset.confirmed === 'True');
        if (allConfirmed) {
            document
                .querySelectorAll('.actions-confirmed')
                .forEach((element) => {
                    element.classList.remove('disabled');
                });
        } else {
            document
                .querySelectorAll('.actions-confirmed')
                .forEach((element) => {
                    element.classList.add('disabled');
                });
        }
    }

    checkAllConfirmed();

    document.querySelectorAll('.disassembly-action').forEach((actionBtn) => {
        actionBtn.addEventListener('click', async function (e) {
            const isConfirmed = actionBtn.dataset.confirmed === 'True';
            const response = await sendPostRequest(actionBtn.dataset.url, {
                is_confirmed: !isConfirmed,
            });
            if (response.ok) {
                actionBtn.dataset.confirmed = isConfirmed ? 'False' : 'True';
                actionBtn.classList.toggle('confirmed');
                checkAllConfirmed();
            } else {
                genericErrorAlert();
            }
        });
    });
}

export function initializeDisassemblyComments() {
    const textarea = document.querySelector('#disassemblyComments');
    textarea.addEventListener('change', async function () {
        const response = await sendPatchRequest(textarea.dataset.url, {
            comments: textarea.value,
        });
        if (!response.ok) {
            genericErrorAlert();
        }
    });
}

export function initializeDisassemblySidePanel() {
    const panel = document.querySelector('#disassemblySidePanel');
    if (!panel) return;

    panel.querySelectorAll('.disassembly-part').forEach((part) => {
        part.addEventListener('click', async function (e) {
            if (
                [
                    'part-comments-btn',
                    'part-comments',
                    'comments-wrapper',
                ].includes(e.target.className)
            )
                return;
            const response = await sendPostRequest(part.dataset.url);
            const data = await response.json();

            part.classList.remove(part.dataset.status);
            part.classList.add(data.status);
            part.dataset.status = data.status;
            part.querySelector('.status').textContent = data.status_name;

            if (data.status === 'N') {
                part.querySelector('.disassembly-checkbox').checked = false;
            } else {
                part.querySelector('.disassembly-checkbox').checked = true;
            }

            if (['N', 'D'].includes(data.status)) {
                part.querySelector('.part-comments-btn').classList.add(
                    'hidden',
                );
            } else {
                part.querySelector('.part-comments-btn').classList.remove(
                    'hidden',
                );
            }
        });
        const comments = part.querySelector('.part-comments');
        comments.addEventListener('change', async function () {
            const response = await sendPatchRequest(comments.dataset.url, {
                comments: comments.value,
            });
            if (!response.ok) {
                genericErrorAlert();
            }
        });
    });
}

export function initializeEnovaDeviceForm(container) {
    const form = container.querySelector('form');
    if (!form) return;

    function serializeForm(formElement) {
        const formData = new FormData(formElement);
        const serializedData = {};

        for (const [key, value] of formData.entries()) {
            if (key.includes('__')) {
                const [parentKey, childKey] = key.split('__');

                if (!serializedData[parentKey]) {
                    serializedData[parentKey] = {};
                }

                serializedData[parentKey][childKey] = value;
            } else {
                serializedData[key] = value;
            }
        }

        const multiSelects = formElement.querySelectorAll('select[multiple]');
        multiSelects.forEach((select) => {
            if (select.name.includes('__')) {
                const [parentKey, childKey] = select.name.split('__');
                const selectedOptions = Array.from(select.selectedOptions).map(
                    (option) => option.value,
                );
                if (!serializedData[parentKey]) {
                    serializedData[parentKey] = {};
                }
                serializedData[parentKey][childKey] = selectedOptions;
            } else {
                serializedData[select.name] = Array.from(
                    select.selectedOptions,
                ).map((option) => option.value);
            }
        });

        return serializedData;
    }

    form.addEventListener('submit', async function (event) {
        event.preventDefault();
        const submitBtn = form.querySelector("button[type='submit']");
        submitBtn.disabled = true;
        submitBtn.classList.add('disabled');
        const data = serializeForm(form);
        data.code = form.querySelector('#id_code').value;

        const response = await sendPatchRequest(form.dataset.url, data);
        submitBtn.disabled = false;
        submitBtn.classList.remove('disabled');
        if (response.ok) {
            addAlert('success', gettext('Zmiany zostały zapisane.'));
            if (container.classList.contains('window')) {
                container.classList.remove('window-open');
                saveWindowState(container);
            }
        } else {
            const responseData = await response.json();
            if (responseData.message) {
                addAlert('error', responseData.message);
            } else {
                genericErrorAlert();
            }
        }
    });
}
