import { getCookie } from './utils';

export function initializeNotifications() {
    const notificationsContainer = document.getElementById(
        'notificationsContainer',
    );
    if (!notificationsContainer) return;
    const notificationsWindow = notificationsContainer.querySelector(
        '#notificationsWindow',
    );
    const notificationsList = notificationsWindow.querySelector('.window-body');
    const notificationsCounters =
        notificationsContainer.querySelectorAll('.counter');
    let notificationIntervalId = null;

    function renderNotifications(notifications) {
        if (notifications.length === 0) {
            notificationsList.innerHTML = `<p class="empty">${gettext('Brak nowych powiadomień')}</p>`;
            return;
        }

        notificationsList.innerHTML = notifications
            .map((notification) => {
                const date = new Date(notification.created_at);
                return `
                <div class="notification">
                    <div class="notification-wrapper">
                        <small class="notification-date">${date.toLocaleString()}</small>
                        <h4 class="notification-title">${notification.title}</h4>
                        <p class="notification-content">${notification.content}</p>
                    </div>
                    <button class="mark-as-read" data-notification-id="${notification.id}">
                        <span class="icon-close-bold"></span>
                    </button>
                </div>
            `;
            })
            .join('');

        notificationsList
            .querySelectorAll('.mark-as-read')
            .forEach((button) => {
                button.addEventListener('click', (event) => {
                    event.preventDefault();
                    markNotificationAsRead(button.dataset.notificationId);
                });
            });
    }

    function updateNotificationCount(count) {
        notificationsCounters.forEach((counter) => {
            counter.textContent = count > 99 ? '99+' : count;
            const circle = counter.closest('.circle');
            if (circle) {
                if (count > 0) {
                    circle.classList.add('animate');
                } else {
                    circle.classList.remove('animate');
                }
            }
        });
    }

    async function fetchNotifications() {
        const url = `/api/notifications/`;
        try {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const data = await response.json();
            renderNotifications(data.items);
            updateNotificationCount(data.count); // Update count after fetching
        } catch (error) {
            console.error('Error fetching notifications:', error);
            notificationsList.innerHTML = '<p>Error loading notifications.</p>';
        }
    }

    async function markNotificationAsRead(notificationId) {
        const url = `/api/notifications/${notificationId}/mark_as_read/`;
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'X-CSRFToken': getCookie('csrftoken'),
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            await response.json();
            fetchNotifications();
        } catch (error) {
            console.error('Error marking notification as read:', error);
        }
    }

    function startNotificationPolling() {
        fetchNotifications();
        notificationIntervalId = setInterval(fetchNotifications, 15000);
    }

    function stopNotificationPolling() {
        if (notificationIntervalId) {
            clearInterval(notificationIntervalId);
            notificationIntervalId = null;
        }
    }

    startNotificationPolling();
    return stopNotificationPolling;
}
